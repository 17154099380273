<template>
    <div style="background-color: #fff;">
        <top></top>
        <img class="register" src="../assets/register.png" alt="" />
        <div class="content">
            <div v-if="stasut == 0">
                <div class="c33 f34 f-w">账号注册</div>
                <input class="phone f32" v-model="phone" type="number" placeholder="请输入手机号">
                <div class="code d-f j-b f32">
                    <input v-model="code" type="number" placeholder="请输入验证码" /> 
                    <div class="c24 t-c" v-if="countdown == 60" @click="verification">获取验证码</div>
                    <div class="c24 t-c" v-if="countdown != 60">{{countdown}}</div>
                </div>
                <div class="next cff f36 t-c" @click="next_step">
                    下一步
                </div>
            </div>
            <div v-if="stasut == 1">
                <div @click="paymoney" class="next cff f36 t-c">
                    支付 ￥{{invitation_money}}
                </div>
                <div class="d-f j-a">
                    <div @click="invitation(1)" class="t-c f36 c33 tips f-w" :style="{'color':invitation_code == 1 ? '#333':'#757575'}">我有邀请码</div>
                    <div @click="invitation(2)" class="t-c f36 c33 tips f-w" :style="{'color':invitation_code == 2 ? '#333':'#757575'}">服务识别码</div>
                </div>
                <div class="invitation_code d-f j-b f32" v-if="invitation_code == 1">
                    <input v-model="invitation_" type="text" placeholder="请输入邀请码" /> 
                    <div class="c24 t-c cff" @click="price">验证</div>
                </div>
                <div class="invitation_code d-f j-b f32" v-if="invitation_code == 2">
                    <input v-model="supplement_" type="text" placeholder="请输入服务识别码" /> 
                    <div class="c24 t-c cff" @click="supplement_creates">验证</div>
                </div>
                <div class="what f30 c33 t-c mt-20" @click="imgpre" v-if="invitation_code == 2">什么是“服务识别码”？</div>
            </div>
            <div class="c33 f26 guide">
                <p class="f34">首次使用指引: </p>
                <p>1、首次使用云电脑需先注册；<span style="color: red;">注册费用为258元，有“邀请码”为231元（含单机版“信考百分”软件激活码）；有“服务识别码”为30元(针对已注册激活单机版的用户)</span></p>
                <p>2、注册时请填写正确的手机号，注册成功后，单机版“信考百分”软件激活码、云电脑的账号和密码会以短信的形式发送到您的手机，清注意查收；</p>
                <p>3、点击下方的客户端下载，选择适合您的客户端进行下载安装；</p>
                <p>4、客户端完成后，输入账号和密码；开启您的云电脑；</p>
                <p>5、云电脑使用截止日期为每年信息考试结束日期。</p>
            </div>
        </div>
        <bottom></bottom>
    </div>
  </template>
  
  <script lang="ts" setup>
    import top from "./top.vue";
    import wx from "weixin-jsapi"; //导入 wx模块
    import bottom from "./bottom.vue";
    import { showConfirmDialog,showImagePreview } from "vant";
    import { ref, onMounted,onUnmounted,nextTick} from "vue";
    import {verify,sms_send,getOpenId,cloudDesktop_create,getPrice,supplements_,validMachineCode } from "@/axios/index";
    import router from "@/router";
    import { useRoute } from "vue-router";
    const imgpre= async () => {
        showImagePreview(['https://www.xinxikaoshi.com/assets/20250213093543.jpg'+'?time='+new Date().getTime()]);
    }
    const paymoney = async () => {
        if(invitation_code.value == 2 && !verify_show.value){
            showConfirmDialog({
                title: "提示",
                message: "请验证服务识别码",
                showCancelButton:false
            })
            .then(() => {
            })
            return
        }
        if(invitation_code.value == 2 && verify_show.value){
            const { data }: any = await supplements_({
                phone:phone.value,
                machineCode:supplement_.value,
                openId:openid.value,
            });
            if (data.code == 200) {
                var response = data.data.response
                wx.config({
                    debug: false, 
                    appId: response.appId,
                    timestamp: response.timeStamp,
                    nonceStr: response.nonceStr, // 必填，生成签名的随机串
                    signature:response.signType, // 必填，签名
                    jsApiList: ["chooseWXPay", "checkJsApi"] // 必填，需要使用的JS接口列表
                });
                wx.ready(() => {
                    wx.chooseWXPay({
                    appId: response.appId,// 必填，公众号的唯一标识
                    timestamp: response.timeStamp,// 必填，生成签名的时间戳
                    nonceStr: response.nonceStr,// 必填，生成签名的随机串
                    package: response.packageVal,// 统一支付接口返回的prepay_id参数值，提交格式如:prepay_id=|*|*|*)
                    signType: response.signType,// 签名方式，根据后端返回就行，一般默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: response.paySign, // 必填，支付签名
                    success: function (payRes) {
                        showConfirmDialog({
                            title: "提示",
                            message: "支付成功！请注意查收短信，请勿丢失！！！",
                            showCancelButton:false
                        })
                        .then(() => {
                            router.push({
                                path: "./cc",
                                query: {
                                },
                            });
                        })
                    },
                    cancel: function (payRes) {
                        // that.$message.error('取消支付')
                    },
                    fail: function (payRes) {
                        // that.$message.error('支付失败')
                    }
                });
                });
            }
        }else{
            const { data }: any = await cloudDesktop_create({
                phone:phone.value,
                discountCode:invitation_.value,
                openId:openid.value,
            });
            if (data.code == 200) {
                var response = data.data.response
                wx.config({
                    debug: false, 
                    appId: response.appId,
                    timestamp: response.timeStamp,
                    nonceStr: response.nonceStr, // 必填，生成签名的随机串
                    signature:response.signType, // 必填，签名
                    jsApiList: ["chooseWXPay", "checkJsApi"] // 必填，需要使用的JS接口列表
                });
                wx.ready(() => {
                    wx.chooseWXPay({
                    appId: response.appId,// 必填，公众号的唯一标识
                    timestamp: response.timeStamp,// 必填，生成签名的时间戳
                    nonceStr: response.nonceStr,// 必填，生成签名的随机串
                    package: response.packageVal,// 统一支付接口返回的prepay_id参数值，提交格式如:prepay_id=|*|*|*)
                    signType: response.signType,// 签名方式，根据后端返回就行，一般默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: response.paySign, // 必填，支付签名
                    success: function (payRes) {
                        showConfirmDialog({
                            title: "提示",
                            message: "支付成功！请注意查收短信，请勿丢失！！！",
                            showCancelButton:false
                        })
                        .then(() => {
                            router.push({
                                path: "./cc",
                                query: {
                                },
                            });
                        })
                    },
                    cancel: function (payRes) {
                        // that.$message.error('取消支付')
                    },
                    fail: function (payRes) {
                        // that.$message.error('支付失败')
                    }
                });
                });
            }
        }
    };
    // 邀请码
    const invitation_code = ref(0);
    const invitation = (e) => {
        invitation_code.value = e
    };
    // 倒计时
    const countdown = ref(60);
    const timer = ref('');
    // 获取验证码
    const verification =  async () => {
        if(phone.value == ''){
            showConfirmDialog({
                title: "提示",
                message: "请输入手机号",
                showCancelButton:false
            })
            .then(() => {
            })
            return
        }
        const { data }: any = await sms_send({
            phone:phone.value
        });
        
        if (data.code == 200) {
            showConfirmDialog({
                title: "提示",
                message: "验证码发送成功",
                showCancelButton:false
            })
            .then(() => {
            })
            startCountdown()
        }
    };
    const startCountdown = () => {
        if (timer.value) { // 如果定时器已存在，先清除它
            clearInterval(timer.value);
        }
        countdown.value = 60; // 重置倒计时为60秒
        timer.value = setInterval(() => {
            if (countdown.value > 0) {
                countdown.value--; // 递减倒计时
            } else {
                countdown.value = 60
                clearInterval(timer.value); // 递归调用，继续倒计时
            }
        }, 1000); // 每秒更新一次倒计时
    };
    // 下一步
    const stasut = ref(0);
    const phone = ref('');
    const code = ref('');
    const next_step =  async () => {
        if(code.value == ''){
            showConfirmDialog({
                title: "提示",
                message: "请输入验证码",
                showCancelButton:false
            })
            .then(() => {
            })
            return
        }
        const { data }: any = await verify({
            phone:phone.value,
            code:code.value
        });
        if (data.code == 200) {
            clearInterval(timer.value); // 递归调用，继续倒计时
            stasut.value = 1
            price()
        }
    };
    const invitation_ = ref('');
    const invitation_money = ref(258);
    const supplement_ = ref("");
    const verify_show = ref(false);
    //获取差价金额
    
    const supplement_creates = async () => {
        const { data }: any = await validMachineCode({
            // machineCode:'06F86910D6C4D2A9'
            machineCode:supplement_.value
        });
        if (data.code == 200) {
            showConfirmDialog({
                title: "提示",
                message: "验证成功",
                showCancelButton:false
            })
            .then(() => {
            })
            invitation_money.value =  data.data.price/100
            verify_show.value = true
        }
    };
    // 获取支付金额
    const price =  async () => {
        const { data }: any = await getPrice({
            discountCode:invitation_.value
        });
        if (data.code == 200) {
            if(invitation_.value != ''){
                showConfirmDialog({
                    title: "提示",
                    message: "验证成功",
                    showCancelButton:false
                })
                .then(() => {
                })
            }
            invitation_money.value =  data.data/100
            verify_show.value = false
        }
    }
    const screenWidth = ref(window.innerWidth);
    const data = ref({
    oldScrollTop: 0,
    });
    const handleResize = () => {
    screenWidth.value = window.innerWidth;
    };
    const route = useRoute();
    const openid = ref('');
    onMounted(async()  => {
        // return
        if (route.query.code != undefined) {
            console.log(route.query.code,123);
            const { data }: any = await getOpenId({
                code:route.query.code
            });
            if (data.code == 200) {
                openid.value = data.data
            }else if(data.code == 500){
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf53176b8ca3b5486&redirect_uri=https%3A%2F%2Fwww.xinxikaoshi.com%2Fregister&response_type=code&scope=snsapi_base#wechat_redirect';
            }
        }else if(route.query.code == undefined){
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf53176b8ca3b5486&redirect_uri=https%3A%2F%2Fwww.xinxikaoshi.com%2Fregister&response_type=code&scope=snsapi_base#wechat_redirect';
        }
        window.addEventListener('resize', handleResize);
        nextTick(() => {
          document.oncontextmenu = new Function("event.returnValue=false");
          document.onselectstart = new Function("event.returnValue=false");
          document.addEventListener("keydown", function (e) {
            if (e.key == "F12") {
              e.preventDefault(); // 如果按下键F12,阻止事件
            }
          });
        })
    });
    onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
    });
  
  </script>
  
  <style lang="scss" scoped>
  .register{
    width: 100%;
    display: block;
  }
  .content{
    margin: 60px 40px;
    .phone{
        padding: 33px 41px;
        background: #F4F6F8;
        border-radius: 12px;
        border: 0;
        width: calc(100% - 82px);
        margin-top: 40px;
    }
    .code{
        padding: 33px 41px;
        background: #F4F6F8;
        border-radius: 12px;
        background: #F4F6F8;
        margin-top: 32px;
        input{
            background: #F4F6F8;
            border: 0;
            width: calc(100% - 250px);
        }
        div{
            width: 170px;
        }
    }
    .next{
        width: 100%;
        height: 96px;
        line-height: 96px;
        background: linear-gradient(90deg, #067FFE, #0EC1E0);
        margin-top: 60px;
        border-radius: 12px;
    }
    .guide{
        margin-top: 130px;
    }
    p{
        margin: 0;
        padding: 0;
        line-height: 60px;
    }
    .tips{
        margin: 50px 0;
        text-decoration: underline;
    }
    .invitation_code{
        input{
            padding: 33px 41px;
            background: #F4F6F8;
            border: 0;
            border-radius: 12px;
            width: calc(100% - 330px);
        }
        div{
            width: 210px;
            background: linear-gradient(90deg, #067FFE, #0EC1E0);
            border-radius: 12px;
            height: 96px;
            line-height: 96px;
        }
    }
  }
  .what{
    text-decoration: underline;
  }
  </style>