<template>
    <div>
        <top :releasess="releasess" :releasess_="releasess_"></top>
        <img v-show="screenWidth > 740" class="banner" src="../assets/banner.jpg" />
        <img v-show="screenWidth < 740" class="banner" src="../assets/phone.jpg" />
        <div class="problem">
            <div class="card-wrap">
                <div class="c33 f32 title p-r">常见问题</div>
                <div class="problem_list p-r">
                    <div class="lists">
                        <div class="c33 f16 ov" @click="problems(item)" v-for="(item, index) in problem" :key="index"
                            v-show="index % 2 == 0">· {{ item.question }} </div>
                    </div>
                    <div class="lists lists_">
                        <div class="c33 f16 ov" @click="problems(item)" v-for="(item, index) in problem" :key="index"
                            v-show="index % 2 != 0">· {{ item.question }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="service">
            <div class="card-wrap">
                <div class="c33 f32 title p-r">技术服务</div>
                <div class="d-f j-a" v-show="screenWidth > 740">
                    <div class="d-f j-b technical">
                        <img src="../assets/support2.png" alt="">
                        <div>
                            <div class="c00 f22">技术专员</div>
                            <div class="c66 f18">为您提供各种在线专业咨询，高效、便捷、无需等待 服务热线：18031089711</div>
                        </div>
                    </div>
                    <div class="d-f j-b technical technicals">
                        <img src="../assets/support3.png" alt="">
                        <div>
                            <div class="c00 f22">扫码技术支持</div>
                            <div class="c66 f18">专业人员为您提供贴心服务，技术 交流，竭诚为您服务</div>
                        </div>
                        <img class="ewm" src="../assets/ewm1.jpg" alt="">
                    </div>
                </div>
                <div class="service_ t-c d-f j-a" v-show="screenWidth < 740">
                    <!-- <div class="services">
                    <img src="../assets/support1.png" alt="">
                    <div class="c00 f20 f-w">在线客服</div>
                    <div class="c66 f16">
                        为您解决常见问题，提供便捷的服务入口，无需排队，衷心期待为您服务
                    </div>
                    <div class="c66 f16 time">服务时间：周一至周六 09:00-18:00</div>
                    <div class="but cff f20" @click="openQQ">点击咨询</div>
                </div> -->
                    <div class="services">
                        <img src="../assets/support2.png" alt="">
                        <div class="c00 f20 f-w">技术专员</div>
                        <div class="c66 f16">
                            为您提供各种在线专业咨询，高效、便捷、无需等待
                        </div>
                        <div class="c66 f16 time">服务时间：周一至周六 09:00-18:00</div>
                        <div class="c33 f20 f-w">服务热线：18031089711</div>
                    </div>
                    <div class="services">
                        <img src="../assets/support3.png" alt="">
                        <div class="c00 f20 f-w">扫码获取技术支持</div>
                        <div class="c66 f16">
                            专业人员为您提供贴心服务，技术交流，竭诚为您服务
                        </div>
                        <img class="ewm" src="../assets/ewm1.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="video" style="display: none;">
            <div class="card-wrap">
                <div class="cff f32 title p-r">演示视频</div>
                <div class="p-r videos">
                    <div class="cff f14 p-a">智测宝演示视频</div>
                    <button class="p-a" :style="{
                        opacity: videoPlaying ? '0' : '1',
                    }" @click="togglePlay">
                        <img v-show="!videoPlaying" src="../assets/play.png" alt="">
                    </button>
                    <video ref="video" controls>
                        <source src="../assets/movie.ogg" type="video/mp4">
                        Your browser does not support the video tag.s
                    </video>
                </div>
            </div>
        </div>
        <!-- <div class="mask" v-if="pro_show">
            <div class="mask_ p-r">
                <div class="d-f j-b f22 cff">常见问题 <img @click="pro_show = false" src="../assets/guanbi.png" /></div>
                <div class="cont">
                    <div class="d-f c33 f18 f-w"><img src="../assets/problem.png" alt=""> {{ problem[pro_index].question }}
                    </div>
                    <div class="c33 f16" v-html="problem[pro_index].content"></div>
                    <div class="buts cff f18 t-c" @click="pro_show = false">我知道了</div>
                </div>
            </div>
        </div> -->
        <bottom :releasess="releasess" :releasess_="releasess_"></bottom>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, onUnmounted,nextTick } from "vue";
import {
    list,lastVersionReleases
} from "@/axios/index";
import router from "@/router";
import top from "./top.vue";
import bottom from "./bottom.vue";
const problem = ref([]);
const qqNumber = ref('38090539');
// const openQQ = async () => {

//     const qqChatUrl = `tencent://message/?uin=516078176&Site=www.xinxikaoshi.com&Menu=yes`;
//     window.location.href = qqChatUrl;
// };
const lists = async () => {
    const { data }: any = await list({});
    if (data.code == 200) {
        problem.value = data.data
    }
};
const video = ref(null);
const pro_index = ref(0);
const show = ref(1);
const videoPlaying = ref(false);
const screenWidth = ref(window.innerWidth);

const handleResize = () => {
    screenWidth.value = window.innerWidth;
};

const problems = (val) => {
    const newpage = router.resolve({
        path: '/details',  // 跳转的页面路由
        query: { // 要传的参数
          data: val.id
        }
      })
      window.open(newpage.href, '_blank') 
};
const togglePlay = () => {
    if (video.value.paused) {
        video.value.play();
        videoPlaying.value = true;
    } else {
        video.value.pause();
        videoPlaying.value = false;
    }
};

const releasess = ref();
const releasess_ = ref();
const lastVersionReleasess = async () => {
    const { data }: any = await lastVersionReleases({});
    if (data.code == 200) {
        releasess.value = data.data.url
        releasess_.value =data.data.url2
    };
};
onMounted(() => {
    lastVersionReleasess();
    lists();
    window.addEventListener('resize', handleResize);
    watch(videoPlaying, (newValue) => {
        if (newValue) {
            video.value.play();
        } else {
            video.value.pause();
        }
    });
    nextTick(() => {
      document.oncontextmenu = new Function("event.returnValue=false");
      document.onselectstart = new Function("event.returnValue=false");
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    })
});
onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

</script>

<style lang="scss" scoped>
.technical {
    padding: 20px;
    background-color: #F7F7F7;
    width: 550px;
    height: 100px;
    img {
        width: 90px;
        height: 90px;
        margin-right: 11px;
    }
    .ewm{
        width: 120px;
        height: 120px;
    }
    .f18 {
        width: 380px;
        margin-right: 20px;
        margin-top: 10px;
        line-height: 30px;
    }
}

.technicals {
    .f18 {
        width: 277px;
        margin-right: 20px;
    }
}

.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);

    .mask_ {
        max-width: 1200px;
        border-radius: 15px;
        overflow: hidden;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .cff {
            padding: 0 54px;
            height: 83px;
            background: linear-gradient(90deg, #2571FA, #2E84FD);

            img {
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        .cont {
            padding: 31px 54px;
            background-color: #fff;

            div {
                &:nth-child(1) {
                    margin-bottom: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
            }

            .buts {
                width: 160px;
                height: 48px;
                background: linear-gradient(90deg, #2571FA, #2E84FD);
                border-radius: 4px;
                line-height: 48px;
                margin: 160px auto 0 auto;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 740px) {
    .mask_ {
        width: 95% !important;
    }

    .buts {
        width: 160px;
        height: 70px !important;
        line-height: 70px !important;
    }

    .services {
        width: 80% !important;
        height: auto !important;

        .f16 {
            line-height: 60px !important;
        }
    }
}

@media screen and (max-width: 1240px) {
    .mask_ {
        width: 80%;
    }

    .problem_list {
        width: 90% !important;
        margin-left: 5% !important;
        display: block !important;
        padding: 30px 0 0 0 !important;

        &::after {
            display: none;
            content: '';
        }

        .lists {
            width: 90% !important;

            div {
                padding-left: 30px !important;
            }
        }

        // .lists_ {
        //     .c33{
        //         padding-left: 0 !important;
        //     }
        // }
    }

    .service_ {
        display: block;

        .services {
            margin: 20px auto;
            margin-bottom: 20px
        }
    }

    .videos {
        width: 90% !important;
        margin-left: 5% !important;

        video {
            width: 100% !important;
            margin-left: 0 !important;
        }

        .f14 {
            left: 0 !important;
            width: calc(100% - 60px) !important;

        }
    }
}

.video {
    min-width: 1200;
    height: 819px;
    background-image: url("../assets/video.png");
    background-size: 100% 100%;

    .title {
        &::after {
            background: linear-gradient(270deg, #FFFFFF 0%, #39455B 100%);
        }

        &::before {
            background: linear-gradient(90deg, #FFFFFF 0%, #37445B 100%);
        }
    }

    video {
        margin-left: 100px;
        width: 1000px;
        height: 600px;
        background: #474B51;
        border-radius: 8px;
    }

    .f14 {
        border-radius: 8px 8px 0 0;
        padding: 20px 30px;
        width: 940px;
        background: rgb(0, 0, 0, 0.4);
        left: 100px;
    }

    button {
        width: 88px;
        height: 88px;
        top: 50%;
        left: 50%;
        margin-left: -44px;
        margin-top: -44px;
        background-color: rgb(0, 0, 0, 0);
        border: 0;
        padding: 0;
        z-index: 99;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.banner {
    width: 100%;
    height: auto;
    display: block;
}

.service {
    min-width: 1200;
    background: #fff;
    margin-top: 70px;
    padding-bottom: 70px;

    .services {
        padding: 33px 26px;
        width: 318px;
        height: 300px;
        background: #F7F7F7;

        img {
            width: 101px;
            height: 101px;
        }

        .f-w {
            margin-bottom: 18px;
        }

        .f16 {
            line-height: 25px;
        }

        .time {
            margin-top: 10px;
        }

        .but {
            width: 260px;
            height: 44px;
            background: #3082FF;
            border-radius: 4px;
            line-height: 44px;
            cursor: pointer;
            margin: 25px auto 0 auto;
        }

        .c33 {
            margin-top: 40px;
        }

        .ewm {
            width: 150px;
            height: 150px;
            margin-top: 14px;
        }
    }
}

.problem {
    min-width: 1200;
    width: 100%;
    background: #F7F7F7;

    .problem_list {
        width: 1090px;
        padding: 40px 55px 0px 55px;
        background: #FFFFFF;
        border-radius: 15px;
        display: flex;
        overflow: hidden;

        .lists {
            width: 50%;

            .c33 {
                padding-bottom: 31px;
                cursor: pointer;

                &:hover {
                    color: #3082FF !important;
                }
            }
        }

        .lists_ {
            .c33{
                padding-left: 55px;
            }
        }

        &::after {
            content: "";
            width: 1px;
            height: 320px;
            background: linear-gradient(0deg, #FFFFFF 0%, #E5E5E5 50%, #FFFFFF 100%);
            position: absolute;
            left: 50%;
            top: 0;
        }
    }
}

.title {
    text-align: center;
    padding: 70px 0 50px 0;

    &::after {
        content: "";
        position: absolute;
        width: 150px;
        height: 2px;
        background: linear-gradient(270deg, #D9DCDD 0%, #F4F9FA 100%);
        left: 50%;
        margin-left: -240px;
        margin-top: 12px;
        top: 50%;
    }

    &::before {
        content: "";
        position: absolute;
        width: 150px;
        height: 2px;
        background: linear-gradient(90deg, #D9DCDD 0%, #F4F9FA 100%);
        right: 50%;
        margin-right: -240px;
        margin-top: 12px;
        top: 50%;
    }
}
</style>