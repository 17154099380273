import request from "@/utils/axios";
// 获取问题
const list = (data: any) => {
  return request("/api/faq/list", "GET", data);
};
// 获取下周地址
const lastVersionReleases = (data: any) => {
  return request("/api/registerClient/lastVersionReleases", "GET", data);
};
// 验证验证码
const verify = (data: any) => {
  return request("/api/sms/verify", "GET", data);
};
// 获取验证码
const sms_send = (data: any) => {
  return request("/api/sms/send", "GET", data);
};

// 获取openid
const getOpenId = (data: any) => {
  return request("/api/payOrder/getOpenId", "GET", data);
};

// 支付
const cloudDesktop_create = (data: any) => {
  return request("/api/payOrder/cloudDesktop/create", "POST", data);
};
// 获取支付价格
const getPrice = (data: any) => {
  return request("/api/payOrder/getPrice", "get", data);
};
// 验证服务码
const validMachineCode = (data: any) => {
  return request("/api/payOrder/validMachineCode", "get", data);
};
//获取
const supplements_ = (data: any) => {
  return request("/api/payOrder/cloudDesktop/supplement/create", "post", data);
};
export {
  list,
  lastVersionReleases,
  verify,
  sms_send,
  getOpenId,
  cloudDesktop_create,
  getPrice,
  supplements_,
  validMachineCode
};
