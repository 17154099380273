import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import details from "../views/details.vue";
import home from "../views/home.vue";
import support from "../views/support.vue";
import cc from "../views/cc.vue";
import register from "../views/register.vue";

import { showNotify } from "vant";
const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/details",
    name: "details",
    component: details,
  },
  {
    path: "/support",
    name: "support",
    component: support,
  },
  {
    path: "/cc",
    name: "cc",
    component: cc,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to: any, from: any, next: any) => {
  document.title = "信考百分 信考软件-信息科技考试测泙系统";
  if (
    to.name != "about" &&
    to.name != "newpatriarchbinding" &&
    to.name != "newstudentbinding" &&
    to.name != "home"
  ) {
  }
  next();
});
router.afterEach((to: any, from: any) => { });

export default router;
