<template>
    <div style="width: 100%;overflow-x: hidden;">
        <top :releasess="releasess" :releasess_="releasess_"></top>
        <div>
            <div class="introduction">
                <div class="card-wrap">
                    <div class="d-f p-r">
                        <img class="left" src="../assets/cc1.png" />
                        <img class="right p-a" src="../assets/cc2.png" />
                    </div>
                    <div class="d-f introduction_text j-b mt-20">
                        <img @click="close" src="../assets/tutorial.png" />
                        <img @click="register_ewm = true" src="../assets/cc3.png" />
                        <img style="opacity: 0;" src="../assets/cc3.png" />
                    </div>
                </div>
            </div>
            <div class="download">
                <div class="card-wrap">
                    <div class="c33 f20 f-w">客户端下载列表</div>
                    <div class="download_list">
						<div class="download_con">
                            <div class="d-f">
                                <img class="download_img" src="../assets/windows.png" alt="" />
                                <div class="introductions">
                                    <div>Windows</div>
                                    <div>win7以上版本，支持32位/64位</div>
                                </div>
							</div>
							<a href="https://desk.ctyun.cn/desktop/software/clientsoftware/download/98608b8c387dfeaf48698f514e3c657f">
								<img class="download_up" src="../assets/updata.png" alt="" />
							</a>
						</div>
						<div class="download_con">
                            <div class="d-f">
                                <img class="download_img" src="../assets/iosewm.png" alt="" />
                                <div class="introductions">
                                    <div>移动端</div>
                                    <div>使用ios/Android</div>
                                </div>
							</div>
							<select class="btn select"  @change="handleChange($event)">
								<option disabled="" value="" selected="">选择下载</option>
								<option class="h5-iphone" value="https://apps.apple.com/cn/app/id1454784442">iOS 下载</option>
								<option class="h5-android" value="https://desk.ctyun.cn/desktop/software/clientsoftware/download/ae4b7b316f2162d75a9fb76f340515f8">Android 下载</option>
							</select>
						</div>
						<div class="download_con">
                            <div class="d-f">
                                <img class="download_img" src="../assets/ios.png" alt="" />
                                <div class="introductions">
                                    <div>Mac</div>
                                    <div>支持macOS 10.14及以上版本</div>
                                </div>
							</div>
							<a href="https://desk.ctyun.cn/desktop/software/clientsoftware/download/72aff953171791285acee2093d94c2b9">
                                <img class="download_up" src="../assets/updata.png" alt="" />
                            </a>
						</div>
						<div class="download_con">
                            <div class="d-f">
                                <img class="download_img" src="../assets/web.png" alt="" />
                                <div class="introductions">
                                    <div>Web客户端</div>
                                    <div>访问地址:https://pc.ctyun.cn推荐使用Chrome70+版本以上讯问</div>
                                </div>
                            </div>
							<a target="_blank" href="https://pc.ctyun.cn/"><img class="download_up" src="../assets/open.png" alt="" /></a>
						</div>
                    </div>
                </div>
            </div>
            <div class="configuration">
                <div class="card-wrap">
                    <div class="c33 f20 f-w">云电脑适配终端终端适配列表</div>
                    <img src="../assets/yun.png" alt="">
                </div>
            </div>
            <div class="moble" v-if="video">
                <div class="p-r">
                    <img @click="close" class="p-a" src="../assets/guanbi.png" alt="" />
                    <video id="my-video" class="video p-a" controls>
                        <source src="https://exam.xinxikaoshi.com/exam/profile/upload/2025/02/11/CloudDesktopOperation_20250211095053A003.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="register_ewm p-f" v-show="register_ewm">
                <div class="p-r">
                    <img class="ewm" src="../assets/register_ewm.png" alt="">
                    <img @click="register_ewm = false" class="p-a" src="../assets/guanbi.png" alt="" />
                </div>
            </div>
        </div>
        <bottom :releasess="releasess" :releasess_="releasess_"></bottom>
    </div>
  </template>
  
  <script lang="ts" setup>
  import top from "./top.vue";
  import bottom from "./bottom.vue";
    import { ref, onMounted,onUnmounted,nextTick } from "vue";
    import {lastVersionReleases} from "@/axios/index";
    import router from "@/router";
    import { useRoute } from "vue-router";
    const video= ref(false)
    const register_ewm= ref(false)
    const close = () => {
        video.value = !video.value
    }
    const handleChange = (event) => {
        window.location.href = event.target.value;
    };
    const support = (path,index) => {
        router.push({
            path: "./support",
            query: {
            },
        });
    };
    const releasess = ref();
    const releasess_ = ref();
    const lastVersionReleasess = async () => {
        const { data }: any = await lastVersionReleases({});
        if (data.code == 200) {
            releasess.value = data.data.url
            releasess_.value =data.data.url2
        };
    };
    onMounted(() => {
        lastVersionReleasess();
    //   window.addEventListener('resize', handleResize);
    //   nextTick(() => {
    //     document.oncontextmenu = new Function("event.returnValue=false");
    //     document.onselectstart = new Function("event.returnValue=false");
    //     document.addEventListener("keydown", function (e) {
    //       if (e.key == "F12") {
    //         e.preventDefault(); // 如果按下键F12,阻止事件
    //       }
    //     });
    //   })
    });
  onUnmounted(() => {
  });
  
  </script>
  
<style lang="scss" scoped>
    .introduction{
        border-top: 75px solid #1A1C38;
        background-color: #D4EBFF;
        padding-top: 54px;
        padding-bottom: 63px;
        .left{
            width: 717px;
        }
        .right{
            width: 361px;
            right:0;
            top: 93px;
        }
        .introduction_text{
            img{
                width: 219px;
                &:nth-child(1){
                    width:168px ;
                    cursor: pointer;
                }
                &:nth-child(2){
                    width:219px ;
                    cursor: pointer;
                }
            }
        }
    }
    .download{
        background-color: #fff;
        padding: 20px 0;
        .download_list{
            padding: 20px;
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0px 2px 10px 0px rgba(48,130,255,0.3);
            margin: 20px 0;
            .download_con{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 50%;
                &:nth-child(3){
                    margin-top: 50px;
                }
                &:nth-child(4){
                    margin-top: 50px;
                }
                .download_img{
                    width: 73px;
                    height: 73px;
                }
                .download_up{
                    width: 94px;
                    height: 34px;
                    margin-right: 20px;
                }
                .select{
                    background: linear-gradient(90deg, #067FFE, #0EC1E0);
                    width: 126px;
                    height: 34px;
                    color: #fff;
                    border: 0;
                    border-radius: 4px;
                    text-align: center;
                    font-size: 14px;
                    option{
                        color: #333;
                    }
                }
                .introductions{
                    margin-left: 27px;
                    div{
                        &:nth-child(1){
                            font-size: 18px;
                            font-weight: 500;
                            color: #1A1C38;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .configuration{
        background-color: #fff;
        padding: 0 0 20px 0;
        img{
            width: 824px;
            height: 273px;
            display: block;
            margin: 20px auto;
        }
    }
    @media screen and (max-width: 741px) {
        .introduction{
            border-top: 0 !important;
            padding-top: 100px !important;
        }
    }
    @media screen and (max-width: 1200px) {
        .moble{
            .p-r{
                width: 80vw !important;

            }
            .video{
                width: 100% !important;
                left: 0%;
                height: auto !important;
            }
        }
        .left{
            width: 80% !important;
            display: block;
            margin: 0 auto;
        }
        .right{
            display: none;
        }
        .introduction_text{
            justify-content: space-around;
            img{
                &:nth-child(3){
                    display: none;
                }
            }
        }
        .configuration{
            padding: 0px 20px;
            img{
                width: 100% !important;
                height: auto !important;
            }
        }
        .download{
            padding: 20px 20px;
            .download_con{
                width: 100% !important;
                &:nth-child(2){
                    margin-top: 50px;
                }
                .download_img{
                    width: 100px !important;
                    height: 100px !important;
                }
                .download_up{
                    width: 130px !important;
                    height: auto !important;
                    margin-right: 0px !important;
                }
                .select{
                    width: 130px !important;
                    font-size: 20px !important;
                    height: 50px !important;
                }
            }
        }
    }
    .moble{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0,0.4);
        width: 100%;
        height: 100%;
        .p-r{
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 1040px;
            height: 454px;
            img{
                width: 40px;
                height: 40px;
                right: -40px;
                top: -40px;
                cursor: pointer;
            }
            video{
                width: 1040px;
                height: 454px;
            }
        }
    }
    .register_ewm{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0,0.4);
        width: 100%;
        height: 100%;
        z-index: 9999;
        .p-r{
            left: 50%;
            top:50%;
            transform: translate(-50%,-50%);
            width: 300px;
            height: auto;
            .ewm{
                width: 300px;
                height: auto;
            }
            .p-a{
                width: 30px;
                height: 30px;
                bottom: -35px;
                left: 50%;
                transform: translate(-50%,0%);
                cursor: pointer;
            }
        }
    }
</style>