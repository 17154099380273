<template>
  <router-view />
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #687a8d;
}
.cffs{
  color: #FFF104;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
}

.t-c {
  text-align: center;
}

i {
  display: inline-block;
  font-style: normal;
}

ul,
li {
  list-style: none;
}
.f16{
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.f34 {
  font-size: 34px;
}

.f36 {
  font-size: 36px;
}

.f40 {
  font-size: 40px;
}

.f44 {
  font-size: 44px;
}

.f46 {
  font-size: 46px;
}

.f70 {
  font-size: 70px;
}
.cb4{
  color: #B4B6BD;
}
.c14 {
  color: #14c97c;
}
.c3d{
  color: #3D4456;
}
.c49{
  color: #494243;
}
.c24 {
  color: #247af1;
}
.c00{
  color: #000;
}
.cff {
  color: #fff;
}

.cfc {
  color: #fc5e77;
}

.c99 {
  color: #999;
}

.c33 {
  color: #333;
}

.cb3 {
  color: #b3b7c2;
}

.ccc {
  color: #ccc;
}

.c66{
  color: #666;
}
.c6c {
  color: #cccccc;
}

.f-w {
  font-weight: bold;
}

.bd8 {
  background-color: #b8b8b8;
}

.b24 {
  background-color: #247af1;
}

.bff {
  background-color: #fff;
}

.f-w {
  font-weight: bold;
}
.d-f {
  display: flex;
  align-items: center;
}

.j-b {
  justify-content: space-between;
}

.j-a {
  justify-content: space-around;
}

.j-c {
  justify-content: center;
}

.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}

.p-f {
  position: fixed;
}

.ov {
  overflow: hidden; // 超出的文本隐藏
  text-overflow: ellipsis; // 溢出用省略号显示
  white-space: nowrap; // 溢出不换行
}

.mt-10 {
  margin-top: 10px;
}

.t-l {
  text-align: left;
}

.mt-20 {
  margin-top: 20px;
}

.t-l {
  text-align: left;
}
.card-wrap{
  width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
.card-wrap{
  width: 100%;
  margin: 0 auto;
}
}
</style>
